var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"height",attrs:{"id":"content","role":"main"}},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"container"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble"},[_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home ")])],1),_vm._m(0),_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_c('router-link',{attrs:{"to":{
                name: 'shop',
              }}},[_vm._v("shop ")])],1),_vm._m(1),_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_vm._v(" "+_vm._s(_vm.$route.params.slug)+" ")])])]),(_vm.category)?_c('div',{staticClass:"row"},_vm._l((_vm.category.sub_category),function(sub_c,sub_c_idx){return _c('div',{key:sub_c_idx,staticClass:"col-6 col-md-2-custome",staticStyle:{"margin-left":"0px"}},[_c('router-link',{attrs:{"to":{
              name: 'SubCategoryProduct',
              params: {
                category: _vm.category.slug,
                slug: sub_c.slug,
              },
            }}},[_c('div',{staticClass:"box"},[_c('span',{staticClass:"box-txt"},[_c('span',{}),_vm._v(" "+_vm._s(sub_c.name)+" ")])])])],1)}),0):_vm._e(),_c('div',{staticClass:"sort_filter-box"},[_c('div',{staticClass:"sort_filter"},[_c('h5',[_vm._v("Sort By:")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sort_by_price),expression:"sort_by_price"}],staticClass:"sort_form",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sort_by_price=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.price_sorting_asec_desc]}},[_c('option',{attrs:{"value":"sort_by","disabled":""}},[_vm._v("Select Best Match")]),_c('option',{attrs:{"value":"1"}},[_vm._v("price less to high")]),_c('option',{attrs:{"value":"2"}},[_vm._v("price high to less")])])])]),_vm._m(2)])]),_c('div',{staticClass:"container overflow-hidden"},[_c('div',{staticClass:"d-flex justify-content-between border-bottom border-color-1 flex-lg-nowrap flex-wrap border-md-down-top-0 border-md-down-bottView om- mb-3 mt-2"},[_c('h3',{staticClass:"section-title section-title__full mb-0 pb-2 font-size-22"},[_vm._v(" "+_vm._s(_vm.$route.params.slug)+" ")])]),_c('products',{attrs:{"products":_vm.products}})],1),_c('InfiniteLoading',{attrs:{"spinner":"waveDots"},on:{"distange":function($event){0.5},"infinite":_vm.categoryProducts}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{staticClass:"custom_angle_right_icon",attrs:{"src":require("../../assets/images/product_details_angle_right_icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{staticClass:"custom_angle_right_icon",attrs:{"src":require("../../assets/images/product_details_angle_right_icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sort_filter"},[_c('h5',[_vm._v("Filter By Price:")]),_c('form',{staticClass:"min_max_form",attrs:{"action":""}},[_c('div',{staticClass:"price_form"},[_c('div',{staticClass:"price_input"},[_c('label',{attrs:{"for":""}},[_vm._v("Min Price")]),_c('input',{attrs:{"type":"number","placeholder":"Min"}})]),_c('div',{staticClass:"price_input"},[_c('label',{attrs:{"for":""}},[_vm._v("Max Price")]),_c('input',{attrs:{"type":"number","placeholder":"Max"}})])]),_c('button',{staticClass:"filter_btn",attrs:{"type":"submit"}},[_vm._v(" Filter "),_c('i',{staticClass:"fa fa-lg fa-filter"})])])])
}]

export { render, staticRenderFns }